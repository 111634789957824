<template>
    <div :class=" session ? 'stake' : ''">
            <div v-if="!session">
                <v-container>
                        <div style="margin-top:50px; margin-bottom: 25px; text-align: center;" >           
                            <div>
                                <h2>Valle de la Muerte</h2>
                                <h2>An ecosystem that rewards engagement.</h2>
                            </div>
                        </div>
                      
                            <v-col style="text-align: center; margin-bottom: 30px;">
                                <div id="inner-div">
                                    <h2>As the war rages on, </h2>
                                    <h2 style="padding-bottom: 40px">supply lines out of Valle de la Muerte must be kept open.</h2>
                                    <h2> Log in and Stake your cartel to escape Loco Occo’s blockade, </h2>
                                    <h2> and smuggle your $CAFE out of the Valley.</h2>
                                </div>
                            </v-col>

                            <div style="display: flex; justifyContent: center;">
                                    <img
                                        src='../assets/img/connect.gif'
                                        class="display-img"
                                        style="aspectRatio: 3 / 1;"
                                    />
                            </div>
                         <!-- <v-col style="margin-bottom: 50px">
                               <img src="../assets/img/cartel-staking-3d.png" class="display-img"  />
                            </v-col> --> 
                            
                </v-container>
            </div>
            <div v-else >
                <v-container>
                    <div style="text-align: center; margin-top: 30px;  font-size: 20px ;">
                        <div id="inner-div">
                            <p>$CAFE Balance : {{balance.toFixed(3)}} </p>
                            <p>Available to Claim 
                                <br/>
                                <span>{{( unclaimReward + reward).toFixed(5)}}</span>
                                <!-- {{reward.toFixed(5) }} -->
                                <!-- <TotalRewardCounter :param="staked"/> -->
                            </p>
                            <div style="display: flex; justify-content: center;">
                            <button @click="stakeAll" style="text-align: center;">Stake All</button>
                            <button @click="unstakeAll" style="text-align: center;">UnStake All</button>
                            <button @click="update_and_claim_all" style="text-align: center;">Claim All</button>
                    </div>
                        </div>
                        
                    </div>
                    
                    <h2 style="text-align: center; margin-top: 120px;"> GEN 0</h2>
                    <div class="grid" elevation="0">
                        <div v-for="(nft,idx) in Gen0Nft" v-bind:key="idx">
                                <img class="card-img" 
                                    v-bind:key="idx" 
                                    @mousemove="move(idx)" 
                                    @mouseleave="leave(idx)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 70%; margin:auto; display: block;"
                                    @click="stake(0, nft.token_id)" 
                                    :src="'https://ewtd.mypinata.cloud/ipfs/bafybeifw5s2mad4mcr7qqvvzuetk4kp3v2d3uw54wjsfx53lunjqbragey/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                        </div>                
                    </div>

                    <h2 style="text-align: center; margin-top: 25px;">GEN 1</h2>
                    <div class="grid" elevation="0">
                        <div v-for="(nft,idx) in Gen1Nft" :key="idx" style="display: flex; 
                                                                            flex-direction: column;
                                                                            justify-content: center;
                                                                            align-items: center;
                                                                            padding-top: 10px;">
                                <img class="card-img"  
                                    :key="idx + Gen0Nft.length" 
                                    @mousemove="move(idx + Gen0Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="stake(1, nft.token_id)" 
                                    :src="'https://ewtd.mypinata.cloud/ipfs/bafybeienu3old5yt7c23yftcb7pjx2oux7pyaliwgo4btzjbjjvqvjz4nm/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                        </div>                
                    </div>

                    <h2 style="text-align: center; margin-top: 25px;">DEAD GEN</h2>
                    <div class="grid" elevation="0">
                        <div v-for="(nft,idx) in Gen2Nft" :key="idx" style="display: flex; 
                                                                            flex-direction: column;
                                                                            justify-content: center;
                                                                            align-items: center;
                                                                            padding-top: 10px;">
                                <img class="card-img"  
                                    :key="idx + Gen0Nft.length + Gen1Nft.length" 
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="stake(2, nft.token_id)" 
                                    :src="'https://bafybeifmsgltvkzrib4v2ihbuuya2oesxnapveqey5deehyjqgjc4yov2y.ipfs.nftstorage.link/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                        </div>                
                    </div>

                    <h2 style="text-align: center; margin-top: 25px;">CITIZENS</h2>
                    <div class="grid" elevation="0">
                        <div v-for="(nft,idx) in CitizenNft" :key="idx" style="display: flex; 
                                                                            flex-direction: column;
                                                                            justify-content: center;
                                                                            align-items: center;
                                                                            padding-top: 10px;">
                                <img class="card-img"  
                                    :key="idx + Gen0Nft.length + Gen1Nft.length" 
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length + Gen2Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length + Gen2Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="stake(3, nft.token_id)" 
                                    :src="'https://vpbb5hsevds2uiax43ekmhg6o25cnw4dqvqgcatfquwpmai7gnaa.arweave.net/q8IenkSo5aogF-bIphzedrom24OFYGECZYUs9gEfM0A/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                        </div>                
                    </div>

                    <h2 style="text-align: center; margin-top: 25px;">Staked</h2>
                    <div class="staked-area grid" style="margin-bottom:150px">
                    
                            <div v-for="(nft,idx) in staked" :key="idx" style="display: flex; 
                                                                            flex-direction: column;
                                                                            justify-content: center;
                                                                            align-items: center;
                                                                            padding-top: 10px;">
                                <img class="card-img"  :key="idx + Gen0Nft.length + Gen1Nft.length" 
                                    v-if="nft.gen == 0"
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="unstake(0, nft.token_id)" 
                                    :src="'https://ewtd.mypinata.cloud/ipfs/bafybeifw5s2mad4mcr7qqvvzuetk4kp3v2d3uw54wjsfx53lunjqbragey/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                                <img class="card-img"  v-else-if="nft.gen == 1"
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="unstake(1, nft.token_id)" 
                                    :src="'https://ewtd.mypinata.cloud/ipfs/bafybeienu3old5yt7c23yftcb7pjx2oux7pyaliwgo4btzjbjjvqvjz4nm/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                                <img class="card-img"  v-else-if="nft.gen == 2"
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="unstake(2, nft.token_id)" 
                                    :src="'https://bafybeifmsgltvkzrib4v2ihbuuya2oesxnapveqey5deehyjqgjc4yov2y.ipfs.nftstorage.link/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />

                                <img class="card-img"  v-else-if="nft.gen == 3"
                                    @mousemove="move(idx + Gen0Nft.length + Gen1Nft.length + Gen2Nft.length)"
                                    @mouseleave="leave(idx + Gen0Nft.length + Gen1Nft.length + Gen2Nft.length)"
                                    ref="card"
                                    style="border: 1px solid black; max-width: 65%; margin:auto; display: block;"
                                    @click="unstake(3, nft.token_id)" 
                                    :src="'https://vpbb5hsevds2uiax43ekmhg6o25cnw4dqvqgcatfquwpmai7gnaa.arweave.net/q8IenkSo5aogF-bIphzedrom24OFYGECZYUs9gEfM0A/' + nft.token_id + '.png'"  
                                    alt="nft_image"
                                />
                                
                                <span style="padding-top: 10px">
                                    <span v-if="nft.gen==2">DeadGen</span>
                                    <span v-if="nft.gen==3">Citizen</span>
                                    <span v-else>Gen:{{nft.gen}}</span>
                                </span>

                                <span>Reward:</span>
                                <NFTRewardCounter :param=nft />
                            </div>          
                     
                    </div>
                    <v-spacer></v-spacer>
                </v-container>
            </div>
    </div>
</template>
<script>
import { parseToken, viewMethod, callMethod } from "../assets/js/near/utils.tsx"
import { Contract, transactions, utils } from "near-api-js";
const TOKEN_STORAGE_COST = utils.format.parseNearAmount("0.005");
const GAS = "250000000000000";

const Gen0Contract = process.env.VUE_APP_GEN0_ID;
const Gen1Contract = process.env.VUE_APP_GEN1_ID;
const Gen2Contract = process.env.VUE_APP_GEN2_ID;
// Citizens
const Gen3Contract = process.env.VUE_APP_CITIZEN_ID;

const STAKING_CONTRACT = process.env.VUE_APP_STAKING_CONTRACT_ID;
const TOKEN_CONTRACT = process.env.VUE_APP_TOKEN_CONTRACT_ID;

import NFTRewardCounter from "../components/NFTRewardCounter.vue"

export default {
    name: "App",
    components: {
    NFTRewardCounter,
},

    data: function() {
        return {
            session: window.accounts.find((account) => account.active)?.accountId,
            balance: 0,
            loading: true,
            Gen0Nft: [],
            Gen1Nft: [],
            Gen2Nft: [],
            CitizenNft: [],
            wonPackage: null,
            packageDialogOpen: false,
            unclaimReward: 0,
            staked: [],
            registeredStorage:false,
            reward: 0,
            products: [],
            registeredStorage: false,
        }
    },
    methods: {
        // Done
        async stake (Gen, tokenId){
            switch (Gen) {

			case 0:
				// await this.$toast.promise(
				// 	window.Gen0Contract.nft_transfer_call({
				// 		args: { receiver_id: process.env.VUE_APP_STAKING_CONTRACT_ID, token_id: tokenId, msg: `${Gen}`, approval_id:parseInt(tokenId)}, // approval_id: parseInt(tokenId)}
				// 		amount: "1",
				// 		gas: "200000000000000",
				// 	}),
				// 	{ pending: "Staking", success: "Staked", error: "Error staking" }
				// );

                await callMethod(
                    [{
                        accountId : this.session,
                        contractId : Gen0Contract,
                        methodName : "nft_transfer_call",
                        args: { 
                            receiver_id: STAKING_CONTRACT, token_id: tokenId, msg: `${Gen}`, approval_id:parseInt(tokenId)
                        },
                        gas: "250000000000000",
                    }]
                )
				break;


			case 1:
                await callMethod(
                    [{
                        accountId : this.session,
                        contractId : Gen1Contract,
                        methodName : "nft_transfer_call",
                        args: { 
                            receiver_id: STAKING_CONTRACT, token_id: tokenId, msg: `${Gen}`, approval_id:parseInt(tokenId)
                        },
                        gas: "250000000000000",
                    }]
                )

				break;

            case 2:
            await callMethod(
                    [{
                        accountId : this.session,
                        contractId : Gen2Contract,
                        methodName : "nft_transfer_call",
                        args: { 
                            receiver_id: STAKING_CONTRACT, token_id: tokenId, msg: `${Gen}`, approval_id:parseInt(tokenId)
                        },
                        gas: "250000000000000",
                    }]
                )
				break;
            case 3:
            await callMethod(
                    [{
                        accountId : this.session,
                        contractId : Gen3Contract,
                        methodName : "nft_transfer_call",
                        args: { 
                            receiver_id: STAKING_CONTRACT, token_id: tokenId, msg: `${Gen}`, approval_id:parseInt(tokenId)
                        },
                        gas: "250000000000000",
                    }]
                )
                break;

			default:
		    }
        },

        // Done
        async unstake (Gen, tokenId) {
            await callMethod(
                [{
                    accountId : this.session,
                    contractId : STAKING_CONTRACT,
                    methodName : "un_stake",
                    args: { gen: Gen, token_id: tokenId },
                    gas: "250000000000000",
                }]
            )
        },

        // Done
        async claimRewards () { 
            let trans = [];
      
            if (!this.registeredStorage) {
                trans.push(
                    {
                        accountId : this.session,
                        contractId : TOKEN_CONTRACT,
                        methodName : "storage_deposit",
                        gas: "250000000000000",
                        amount: TOKEN_STORAGE_COST,
                    }
                );
            };

            trans.push(
                {
                    receiverId: STAKING_CONTRACT,
                    actions: [
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "claim_reward",
                                args: {},
                                gas: "250000000000000",
                                amount: "0"
                            },
                        }
                    ]
                    
                }
                
            );

            return trans;
        },

        // Doing now
        async stakeAll () {
            let trans = [];
            const wallet = await window.selector.wallet();
            const chunkSize = 3;

            for (let i = 0; i < this.Gen0Nft.length; i += chunkSize) {
                let actionsGen0 = [];
                const gen0chunk = this.Gen0Nft.slice(i, i + chunkSize);
                //Create actions for each chunk
                for (const gen0nft of gen0chunk) {
                    actionsGen0.push(
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "nft_transfer_call",
                                args: {  
                                    receiver_id: process.env.VUE_APP_STAKING_CONTRACT_ID,
                                    token_id: gen0nft.token_id,
                                    msg: `0`,
                                    approval_id: parseInt(gen0nft.token_id)
                                },
                                gas: "100000000000000",
                                deposit: "1",
                            },
                        },
                    )
                }
                //Separate Transaction for each chunk
                trans.push(
                    {
                        receiverId: Gen0Contract,
                        actions: actionsGen0
                    }
                );
            }

            for (let i = 0; i < this.Gen1Nft.length; i += chunkSize) {
                let actionsGen1 = [];
                const gen1chunk = this.Gen1Nft.slice(i, i + chunkSize);
                //Create actions for each chunk
                for (const gen1nft of gen1chunk) {
                    actionsGen1.push(
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "nft_transfer_call",
                                args: {  
                                    receiver_id: process.env.VUE_APP_STAKING_CONTRACT_ID,
                                    token_id: gen1nft.token_id,
                                    msg: `0`,
                                    approval_id: parseInt(gen1nft.token_id)
                                },
                                gas: "100000000000000",
                                deposit: "1",
                            },
                        },
                    );
                };
                
                trans.push(
                    {
                        receiverId: Gen1Contract,
                        actions: actionsGen1
                    }
                );
            }

            for (let i = 0; i < this.Gen2Nft.length; i += chunkSize) {
                let actionsGen2 = [];
                const gen2chunk = this.Gen2Nft.slice(i, i + chunkSize);
                //Create actions for each chunk
                for (const gen2nft of gen2chunk) {
                    actionsGen2.push(
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "nft_transfer_call",
                                args: {  
                                    receiver_id: process.env.VUE_APP_STAKING_CONTRACT_ID,
                                    token_id: gen2nft.token_id,
                                    msg: `0`,
                                    approval_id: parseInt(gen2nft.token_id)
                                },
                                gas: "100000000000000",
                                deposit: "1",
                            },
                        },
                    );
                }
                //Separate Transaction for each chunk
                trans.push(
                    {
                        receiverId: Gen2Contract,
                        actions: actionsGen2
                    }
                );
            }

            for (let i = 0; i < this.CitizenNft.length; i += chunkSize) {
                let actionsGen3 = [];
                const gen3chunk = this.CitizenNft.slice(i, i + chunkSize);
                //Create actions for each chunk
                for (const gen3nft of gen3chunk) {
                    actionsGen3.push(
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "nft_transfer_call",
                                args: {  
                                    receiver_id: process.env.VUE_APP_STAKING_CONTRACT_ID,
                                    token_id: gen3nft.token_id,
                                    msg: `0`,
                                    approval_id: parseInt(gen3nft.token_id)
                                },
                                gas: "100000000000000",
                                deposit: "1",
                            },
                        },
                    );
                }
                //Separate Transaction for each chunk
                trans.push(
                    {
                        receiverId: Gen3Contract,
                        actions: actionsGen3
                    }
                );
            }

            await wallet.signAndSendTransactions({ transactions: trans })
        
        }, 

        //Done
        async unstakeAll () {
            let trans = [];

            for (const nft of this.staked.reverse()) {

                trans.push(
                    {
                        accountId : this.session,
                        contractId : STAKING_CONTRACT,
                        methodName : "un_stake",
                        args: { 
                            gen: nft.gen,
                            token_id: nft.token_id,
                        },
                        gas: "50000000000000",
                    }
                );
            };

            await callMethod(trans);
        },

        // Done
        async update_and_claim_all (){
            const claimReward_trans = await this.claimRewards();

            let trans = [];

            const wallet = await window.selector.wallet();

            const chunkSize = 15;
            
            // const totalGenNft = Gen0Nft.concat(Gen1Nft);
            for (let i = 0; i < this.staked.length; i += chunkSize) {
                let totalActions = [];
                const stakedchunk = this.staked.slice(i, i + chunkSize);

                for (const nft of stakedchunk) {
                    totalActions.push(
                        {
                            type: "FunctionCall",
                            params: {
                                methodName: "user_update_reward",
                                args: {  
                                    gen: nft.gen,
                                    token_id: nft.token_id, 
                                },
                                gas: "10000000000000",
                                deposit: "0",
                            },
                        },
                    );
                }

                //Separate Transaction for each chunk
                trans.push(
                    {
                        receiverId: STAKING_CONTRACT,
                        actions: totalActions
                    }
                );
            }
           
            trans = trans.concat(claimReward_trans);
            console.log("Finall", trans)
            await wallet.signAndSendTransactions({ transactions: trans })
        },

        move (id) {
            const card = this.$refs.card[id]
            // card.style.transform = `perspective(500px)  ${rotY} ${rotX}`;

            const relX = (event.offsetX + 1) / card.offsetWidth;
            const relY = (event.offsetY + 1) / card.offsetHeight;
          
            const rotY = `rotateY(${((relX - 0.5) * 40).toFixed(2)}deg)`;
            const rotX = `rotateX(${((relY - 0.5) * -40).toFixed(2)}deg)`;

            card.style.transform = `perspective(800px)  ${rotY} ${rotX}  scale3d(1, 1, 1)`;
        },

        leave(id) {
            const card = this.$refs.card[id];
            card.style.transform = `perspective(800px)`;
        },

        allRewardTicker: function () {
            setInterval(function () {
                let total = 0;
                console.log(this.staked)
                this.staked.forEach(nft => {
                    total += parseToken(((Date.now() - nft.last_action_time) / 1000 ) * nft.rate);
                }); 
                
                this.reward += total + this.unclaimedReward; 
            }, 300);
        }
    },

    async created (){
        window.Buffer = window.Buffer || require('buffer').Buffer;
        const accountId = window.accounts.find((account) => account.active)?.accountId

        if (accountId) {
            // this.balance = parseToken(await window.tokenContract.ft_balance_of({ account_id : window.accountId }));
            this.balance = parseToken( await viewMethod(process.env.VUE_APP_TOKEN_CONTRACT_ID, "ft_balance_of", { account_id : accountId}));
            this.Gen0Nft =    await viewMethod(process.env.VUE_APP_GEN0_ID, "nft_tokens_for_owner", { account_id : accountId});
            this.Gen1Nft =    await viewMethod(process.env.VUE_APP_GEN1_ID, "nft_tokens_for_owner", { account_id : accountId});
            this.Gen2Nft =    await viewMethod(process.env.VUE_APP_GEN2_ID, "nft_tokens_for_owner", { account_id : accountId});
            this.CitizenNft = await viewMethod(process.env.VUE_APP_CITIZEN_ID, "nft_tokens_for_owner", { account_id : accountId});

        // await window.tokenContract.storage_balance_of({ account_id: window.accountId }) 
        await viewMethod(process.env.VUE_APP_TOKEN_CONTRACT_ID, "storage_balance_of", {account_id: accountId}).then((res) => {
			if (res != null) {
				this.registeredStorage = true;
			}
		});
        // await window.stakingContract.get_unclaimed_reward_by_id({ account_id: window.accountId })
        await viewMethod(process.env.VUE_APP_STAKING_CONTRACT_ID, "get_unclaimed_reward_by_id", { account_id: accountId}).then((res) => {
			console.log("Unclaimed Reward:", parseToken(res));
			this.unclaimReward = parseToken(res);
		});

        // await window.stakingContract.get_staked_by_id({ account_id: window.accountId })
        await viewMethod(process.env.VUE_APP_STAKING_CONTRACT_ID, "get_staked_by_id",{account_id : accountId}).then(async (res) => {
			let reward = [];
			for await (const nft of res) {
				// const re = await window.stakingContract.est_reward({
				// 	token_id: nft.token_id,
				// 	gen: nft.gen,
				// 	account_id: window.accountId,
				// });

                const re = await viewMethod(
                    process.env.VUE_APP_STAKING_CONTRACT_ID,
                    "est_reward",
                    { token_id : nft.token_id, gen : nft.gen, account_id : accountId} 
                );
				reward.push(parseToken(re));
			}

            setInterval(() => {
                let total = 0;
                res.forEach((nft) => {
                    total += parseToken(((Date.now() - nft.last_action_time) / 1000) * nft.rate);
                })
                this.reward = total;
            }, 300)
			this.staked = res;
		});      
        } 
    },
}
</script>
<style lang="scss" scoped>

#inner-div {
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 25px;
    padding: 15px;
    // font-size: 20px;
    border-radius: 15px;
    background-color: rgba(105,105,105,0.7);
}


.display-img {
    // width: 50%; 
    border-radius: 20px;
}

@media screen and (max-width: 750px) {
    .display-img{ 
        transform: scale(0.7);
    }
    h1,h2,h3 {
        font-size: 15px;
    }    
}

.stake {
    // background: url("../assets/img/staking-back.png") center no-repeat fixed;
    background-color:#202020;
    // background-color: rgba(0,0,0,0.5);
    // background-size: auto 100% !important ;
    // background-blend-mode: multiply !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
}

button {
	font-family: "western-regular", Fallback, sans-serif;
	background-color: black;
	color: rgb(213, 168, 168);
    // font-size: 10px !important;
	margin: 5px;
	cursor: pointer;
	font-size: large;
	font-weight: normal;
	padding: 5px 5px 5px 5px;
	border: 2px solid #65172c;
}
.grid {
    margin: auto;
    // display: block;
	padding:5%;
	display: grid;
	grid-template-columns: repeat(4, 2fr);
    // grid-template-rows: 80px auto 80px; 
    row-gap:25px;
    
	// gap:100px;
	grid-auto-rows: minmax(5%, auto);
	justify-content: end;
    background-color: transparent!important;
}
.staked-area {
    border-width: 3px;
    border-style: solid;
    border-image: 
        linear-gradient(to bottom, rgba(0, 0, 0, 0),  rgba(255,255,153, 0.8), rgba(0, 0, 0, 0)) 1 100%;
}

.card-img {
    filter: grayscale(.65);
    position: relative;
    border-radius: 10%;
    // transition: all .1s ease;
    transition: all .1s ease;
    
    z-index: 1;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0);
    box-shadow: 10px 10px rgba(255,255,153, 0.1);
    cursor: pointer;

     &:hover {
    //   transform: scale(2);
      z-index: 2;
      box-shadow: 0 10px 20px rgba(203, 184, 12, 0.6);
    // box-shadow: 
    //     rgba(255, 255, 255, 0.5) 5px 3px,
    //     rgba(255, 255, 255, 0.4) 10px 6px,
    //     rgba(255, 255, 255, 0.3) 15px 9px,
    // ;
      filter: grayscale(0);
     }
      
}

h2,
h1,
h3 {
	font-weight: normal;
}

@media screen and (max-width: 750px) {
    .stake{
        font-size: 12px;
    }

    button {
        font-size: 12px;
    }

    cartel-img {
       box-shadow: 2px 2px rgba(255,255,153, 0.1);
    }

    .grid {
        grid-template-columns: repeat(2, 2fr);
    }

    .display-img {
        width: 110%;
        display: flex;
        justify-content: center;
        height: 110%;
        margin-left: -2%;
        // margin-left:%;
        aspect-ratio: 1.5 / 1;
    }
}
.child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>../assets/js/near/utils.xts