<template>
    <span>
        {{reward}}
    </span>
</template>

<script>
import { parseToken } from "../assets/js/near/utils.tsx"
export default {
    name: "NFTRewardCounter",
    props: [
        'param'
    ],
    data: function() {
        return {
            reward: 0,
            logger: this.param
        }
    },

    // created(){
    //     this.reward = this.nft
    // }

    created(){
        const { last_action_time, rate } = this.logger;
        setInterval(() => {
		    this.reward = (parseToken(((Date.now() - last_action_time) / 1000) * rate).toFixed(5));
	    }, 100);
    }

}
</script>