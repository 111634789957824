import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import PackagePage from '../views/PackagePage.vue';
import StakingPage from '../views/StakingPage.vue';
import AboutTeamPage from '../views/AboutTeam.vue';
import AboutProject from '../views/AboutProject.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: "/staking",
    name: "Staking",
    component: StakingPage,
  },
  // {
  //   path: "/package",
  //   name: "Package",
  //   component: PackagePage,
  // },
  {
    path: "/team",
    name: "Team",
    component: AboutTeamPage,
  },
  {
    path: "/project",
    name: "project",
    component: AboutProject,
  }
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
