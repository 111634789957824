<template>
    <v-container class="mt-5">
        <h2 style="text-align: center; margin-top: 25px; margin-bottom: 50px;">EL CAFE CARTEL Team:</h2>
        <v-row>
            <template v-for="n in 8">
                <v-col :key="n" sm="6" class="mt-5">
                    <v-card class="mx-auto pa-12 pb-5 rounded-xl" min-height="" max-width="600px" outlined style="background:#C0C0C0">
                        <v-img :src="member_description[n-1][2]"/>
                        <v-card-title style="font-size: 24px;" class="pl-0">{{member_description[n-1][0]}}</v-card-title>
                        <v-card-subtitle class="pl-0 pt-2" style="font-size: 18px">{{member_description[n-1][1]}}</v-card-subtitle>
                        <!-- <v-card-text class="pl-0">
                            <div class="text--primary" style="font-size: 16px">
                                {{member_decription[n-1]}}
                            </div>
                        </v-card-text> -->
                    </v-card>
                </v-col>
                    <v-responsive
                    v-if="n === 2"
                    :key="`width-${n}`"
                    width="100%"
                    ></v-responsive>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import benny_pfp from "../assets/img/team_pfp/benny_deadGen.png"
import boba_pfp from "../assets/img/team_pfp/hugeBoba_deadGen.png"
import summo_pfp from "../assets/img/team_pfp/sumo_deadgGen.png"
import macca_pfp from "../assets/img/team_pfp/macca_deadGen.png"
import eric_pfp from "../assets/img/team_pfp/eric_gen2.png"
import fanko_pfp from "../assets/img/team_pfp/fanko_gen2.png"
import boiler_pfp from "../assets/img/team_pfp/boiler_gen2.png"
import iblis_pfp from "../assets/img/team_pfp/iblis_gen2.png"
export default {
data: () => ({
    member_description: [
        ["Benny Blanko", "Co-Founder/CEO", benny_pfp],
        ["Huge Boba","Co-Founder/CTO", boba_pfp], 
        ["Macca","Co-Founder/COO", macca_pfp],
        ["Sumo","Co-Founder/CFO", summo_pfp],
        ["Eric","Developer", eric_pfp],
        ["Funksteady","Artist", fanko_pfp],
        ["Lizzie Borden","Artist", boiler_pfp],
        ["Iblis","Community Manager", iblis_pfp],
    ],
    justify: ['center',],
}),
}
</script>
<style>
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>