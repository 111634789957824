<template>
  <v-app class="my-application">
    <link href="https://use.fontawesome.com/releases/v6.1.1/css/all.css" rel="stylesheet"/>
    <v-navigation-drawer
      v-model="drawer"
      style="max-width: 250px; max-height:100%"
      color="#757575"
      temporary
      app
      left
    >
    <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          class="fill-height"
          justify="center"
        >

          <v-list-item>
            <v-img max-height="70" max-width="120" :src="logo"/>
          </v-list-item>

          <v-list-item class="align-center justify-center fill-height" style="margin-top:0px">
            <v-row class="ma-0 pa-0">
              <v-col
                  cols=""
                >
                  <v-btn
                    class="mx-3"
                    dark
                    href="https://twitter.com/ElCafeCartel" target="_blank"
                    elevation="6" 
                    color="#757575"
                   >
                    <v-icon color="white" >$vuetify.icons.twitter</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-3"
                    dark
                    href="https://discord.gg/k5YJnz9RY2" target="_blank" 
                    elevation="6" 
                    color="#757575"
                   >
                    <v-icon color="white" >$vuetify.icons.discord</v-icon>
                  </v-btn>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item class="align-center justify-center fill-height">
            <v-btn text elevation="12" style="color: white ; font-size: 14px; min-width: 200px;">
             <router-link style="all:unset" to="/project">Project</router-link>
            </v-btn>
          </v-list-item>

          <v-list-item class="align-center justify-center fill-height">
            <v-btn text elevation="12" style="color: white ;font-size: 14px; min-width: 200px;" >
              <router-link style="all:unset" to="/staking">Staking</router-link>
            </v-btn>
          </v-list-item>

           <v-list-item class="align-center justify-center fill-height">
            <v-btn text elevation="12" style="color: white ; font-size: 14px; min-width: 200px;">
             <router-link style="all:unset" to="/package">Lootboxes</router-link>
            </v-btn>
          </v-list-item>

          <v-list-item class="align-center justify-center fill-height">
            <v-btn text elevation="12" style="color: white ; font-size: 14px; min-width: 200px;">
             <router-link style="all:unset" to="/team">Team</router-link>
            </v-btn>
          </v-list-item>

          <v-list-item class="align-center justify-center fill-height">
            <v-btn v-if = "account_id" text elevation="12" style="color: white ;font-size: 14px; min-width: 200px;" @click="logout">Logout</v-btn>
            <v-btn v-else  text elevation="12"  style="color: white ;font-size: 14px; min-width: 200px;" @click="login">Login</v-btn>
          </v-list-item>
          
          
        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>
    <!-- hide-on-scroll -->
    <v-app-bar flat app hide-on-scroll height=100% color="#202020" >
      <router-link to="/">
        <v-img  max-height="50" max-width="100" class="logo-text-out" :src="logo"/>
      </router-link>
     
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color="white" class="d-flex d-md-none " style="margin-right: 15px;" @click="drawer = !drawer"></v-app-bar-nav-icon>
   
      
      <v-container fluid fill-height  class="d-none d-md-flex">
        
        <v-spacer></v-spacer>

        <v-btn  color="#585858" elevation="10"  class="navigation mx-4"  to="project" active-class="no-active" >
          Project
        </v-btn>
       
        <v-btn color="#585858" elevation="10" class="navigation mx-4"  min-width=200px; to="staking" active-class="no-active" > 
          Staking
        </v-btn>
   
        <!-- <v-btn  color="#585858" elevation="10"  class="navigation mx-4"  to="package" active-class="no-active" >
          Lootboxes
        </v-btn> -->

        <v-btn  color="#585858" elevation="10"  class="navigation mx-4"  to="team" active-class="no-active" >
          Team
        </v-btn>

        <v-hover>
          <v-btn v-if = "account_id" color="#585858" elevation="10" class="navigation mx-3" @click="logout">Logout</v-btn>
          <v-btn v-else color="#585858" elevation="10" class="navigation mx-3" @click="login">Login</v-btn>
        </v-hover>

        <v-btn
            class="mx-2"
            color="white"
            style="background: #585858"
            icon
            large
            dark
            href="https://twitter.com/ElCafeCartel" target="_blank"
        >
          <v-icon>$vuetify.icons.twitter</v-icon>
        </v-btn>

        <v-btn
            class="mx-2"
            color="white"
            style="background: #585858"
            icon
            large
            dark
            href="https://discord.gg/k5YJnz9RY2" target="_blank"
        >
          <v-icon>$vuetify.icons.discord</v-icon>
        </v-btn>

        <!-- <v-btn fab dark elevation="10" class=" icons-out mx-2" color="#585858" style="margin-left:20px !important;" href="https://discord.gg/peyZQNHK6d"> 
          <v-icon> <v-img max-height="25" max-width="30" :src="discord"/> </v-icon>
        </v-btn>
        <v-btn icon  elevation="10" class=" icons-out mx-2" href="https://twitter.com/ElCafeCartel">
          <v-img  max-height="25" max-width="30" :src="twitter"/>	
        </v-btn> -->
      </v-container>
    </v-app-bar>
    <!-- <v-content style="background-color: rgba(0,0,0,0.6)"> -->
    <v-main style="background-color:#202020">
      <router-view/>
    </v-main>
    <v-footer padless class="my-footer">
    <v-col
      class="text-center"
      cols="12"
    >
      Same Mud, Same Blood
    </v-col>
  </v-footer>
  </v-app>
</template>
<script>
// import "../src/assets/css/global.css"
import logo from "../src/assets/img/Logos/menu-logo.png";
import discord from "../src/assets/img/Discord_logo.png";
import twitter from "../src/assets/img/twitter_logo.png";
import menuBackground from "../src/assets/img/bg.jpg";
import { login, logout} from "../src/assets/js/near/utils.tsx";
import { Buffer } from 'buffer';
window.Buffer = Buffer;
export default {
  name: "App",
  methods: {
    login, 
    logout,
  },
  data: function () {
      // let session = window.walletConnection.isSignedIn();
      return {
          drawer: false,
          group: null,
          account_id: window.accounts.find((account) => account.active)?.accountId,
          logo: logo,
          menuBackground: menuBackground,
          twitter: twitter,
          discord: discord,
      }
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    // mounted(){
    //   console.log(process.env.VUE_APP_ENVIRONMENT)
    // }
}
</script>
<style lang="scss" scoped>

  @font-face{
    font-family: "western-regular";
	  src: url("./assets/font/western-regular.ttf") format("truetype");
  }
  $font-family: 'western-regular';
  
  .my-application {
    .headline,
    [class*='text-'] {
      font-family: $font-family, sans-serif !important;
    }
    color: white !important;
    font-family: $font-family, sans-serif !important;
    // background: url("../src/assets/img/plantation.png") center no-repeat fixed !important ;
    // background-size: auto 100% !important ;
    // background-blend-mode: multiply !important;
    // background-color: #757575;
    height: 100% !important;
    width: 100% !important;
    // position: fixed !important;
    top: 0 !important;
    // z-index: -1 !important;



    .navigation {
      // color: rgb(88,0,0);
      color: white;
      // background: transparent;
      // background-color: white;
      // color: red ;
      font-weight: 400;
      font-size: 14px ;
      // text-decoration: none;
    }

    .v-btn:hover{
      // color: rgb(88,0,0) !important;
      color: white !important;
      // color: red;
    }




    .v-list-item-group {
      justify-content: center!important;
    }
    .v-list-item:hover {
      background-color: transparent !important;
    }
    .v-list-item {
      &--active {
        background-color: transparent ;
          .active {
          display: inline-block!important;
        }
      }
      margin-top: 20px;
      font-style: normal;
      font-weight: 900;
      // padding-top: 20px;
      text-align: center !important;
      // background-color: transparent !important;
      // font-size: 14px;
      color: rgba(88,0,0,0.1) !important;
      justify-content: center!important;
      letter-spacing: 0.20em;
      // text-indent: 10px; //20
      text-transform: uppercase;
    }

    @media screen and (max-width: 1200px) {
      .icons-out {
        display: none;
      }
    }

     @media screen and (max-width: 400px) {
      .logo-text-out {
       width: 100px;
      }
    }

    .my-footer {
      color: white !important;
      font-weight: 500 !important;
      font-size: 18px !important;
      background-color: #202020 !important;
      // background: url("../src/assets/img/bg.jpg");
      // background-repeat: no-repeat; 
      // background-position:center; 
      background-size: cover;
    }

  }

  .v-btn--active.no-active::before {                                                                             
    opacity: 0 !important;
  }



</style>
./assets/js/near/utils.xts