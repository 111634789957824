<template>
 <div>
    <v-container style="margin-top: 50px;margin-bottom: 50px;">
      <div  
        v-if="session"
        id="demo-text"
        >
        
         <p style="display: flex; justify-content: center; align-items:center" >$CAFE Balance : {{balance.toFixed(3)}} </p> <br>

         <!-- <p style="display: flex; justify-content: center; align-items:center" v-if="contracts.length == 0 ">Unfortunately, all the packages are sold out!</p> -->
         <p style="display: flex; justify-content: center; align-items:center" v-if="contracts.length == 0 ">Upcoming: Gen 2 WL Tokens </p>
         

    
          
      

         <v-dialog 
            v-model="setPackageDialogOpen" 
            max-width="600"
          >
          <template v-slot:activator="{ on, attrs }">
           </template>
            <v-card
            :img="menuBackground"
            >
                <v-img v-if="!Nft_won" :src="Box_Cafe"/> <v-img v-else :src="Box_NFT" />
                <v-card-text style="text-align: center; font-size: 18px; color:rgb(88,0,0);margin-top: 20px;" >You won {{setWonPackage}}</v-card-text>
                <v-card-actions style="display:flex; align-items:right; justify-content: right; ">
                  <v-btn  @click.stop="setPackageDialogOpen=false, Nft_won=false">Close</v-btn>
                </v-card-actions> 
            </v-card>
        </v-dialog>



    </div>
      <div
        v-else 
        id="demo-text"
       > 
        <p style="display: flex; justify-content: center; align-items:center" >Log In to start Playing EL Cafe Cartel Lootboxes!</p>
        <p style="display: flex; justify-content: center; align-items:center" v-if="contracts.length == 0 ">Upcoming: Gen 2 WL Tokens</p>
      </div>
      <v-row >
      <v-col v-for="(item,idx) in contracts" :key="item[0]" cols="12" sm="4" >
        <v-hover v-slot="{ hover}">
          <v-card 
          :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto my-12 glow "
            :img="menuBackground"
            max-width="325"
          > 
            <!-- <v-img  :src="'https://ewtd.mypinata.cloud/ipfs/bafybeienu3old5yt7c23yftcb7pjx2oux7pyaliwgo4btzjbjjvqvjz4nm/' + idx + '.png'"/> -->
            <v-img :src=item[3]> </v-img>
            <v-card-title>{{item[0]}}</v-card-title>
            <v-card-text style="color: rgb(88,0,0); font-size: large;">
          <v-row
            align="center"
            class="mx-0"
            style="margin-bottom: 2px; margin-top: 2px;"
          >Wanted:
            <v-rating
              :value="10 - item[1]/10"
              color="rgb(88,0,0) "
              background-color="rgba(88,0,0, 0.5)"
              dense
              half-increments
              length="10"
              readonly
              size="16"
            ></v-rating>

            <!-- <div class="grey--text ms-4">
              4.5 (413)
            </div> -->
          </v-row>
        <v-row align="center"
            class="mx-0"> <div>Availability: {{item[2]}}</div>
          </v-row>
          </v-card-text>
          <v-divider style="color:rgb(88,0,0)" class="mx-4"></v-divider>
          <!-- <v-card-title>Tonight's availability</v-card-title> -->
            <v-card-actions style="display:flex; align-items:center; justify-content: center; margin-top: 5px; margin-bottom: 10px;">
              <v-btn v-if="session"
                elevation="12"
                min-width="100px"
                @click="playPackage(item[0])"
              >
                Buy 100 $CAFE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    </v-container>
    
  </div>
</template>

<script>
import menuBackground from "../assets/img/bg.jpg"
// import PackageReward from '../components/PackageReward'
import Box_Cafe from "../assets/img/packageDone.gif";
import Box_NFT from "../assets/img/nft-box-victory.gif";
import { nft_availability, parseToken } from "../assets/js/near/utils.tsx";
export default {
  name: 'PackagePage',
  methods: { nft_availability, parseToken },
  data: function(){
    return {
      Nft_won: null,
      Box_Cafe: Box_Cafe,
      Box_NFT: Box_NFT,
      session: window.walletConnection.isSignedIn(),
      menuBackground: menuBackground,
      balance: 0,
      contracts: [],
      setWonPackage: null,
      setPackageDialogOpen: false,
      showScheduleForm: false,
    }
  },
  components: {
    // PackageReward
  },

  methods: {
    //pethod to play the game
      async playPackage(name){
        await window.tokenContract.package_act({ args:{contract_id : name}, amount: 1, gas: "200000000000000",});
      }
      
  },

  async created(){
    let package_contracts = await window.tokenContract.get_contracts({});
    let img = "";
    let len = 0;

    package_contracts.forEach( async (init) => {
      let arr = await nft_availability(init);
      this.contracts.push(arr)
    });

    if (window.walletConnection.isSignedIn()) {
            this.balance = parseToken(await window.tokenContract.ft_balance_of({ account_id : window.accountId }));
      }
    
      //Hash Search
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let transactionHashes = params.get("transactionHashes");
      if (!transactionHashes) return;
			if (transactionHashes.includes(",")) {
				transactionHashes = transactionHashes.split(",")[0];
			}
      const tx = await window.provider.txStatus(transactionHashes, process.env.VUE_APP_TOKEN_CONTRACT_ID);
      if ("status" in tx) {
        console.log(tx);
        if ("SuccessValue" in tx.status) {
          if (tx.status.SuccessValue != "") {
            const packageWon = decodeURIComponent(escape(window.atob(tx.status.SuccessValue)));
            switch (packageWon) {
              case "1":
                this.$toast.success("You won the Small Prize package!", {
                  position: 'top-right'
                });
                this.setWonPackage = "Small(15) CAFE";
                this.setPackageDialogOpen = true;
                break;
              case "2":
                this.$toast.success("You won the Medium Prize package!",{
                  position: 'top-right'
                });
                this.setWonPackage = "Medium(100) CAFE";
                this.setPackageDialogOpen = true;
                break;
              case "3":
                this.$toast.success("You won the Big Prize package!", {
                  position: 'top-right'
                });
                this.setWonPackage = "Big(250) CAFE";
                this.setPackageDialogOpen = true;
                break;
              case "4":
                this.$toast.success("You won the NFT Prize package!", {
                  position: 'top-right'
                });
                this.setWonPackage = "an NFT!";
                this.setPackageDialogOpen = true;
                this.Nft_won = true;
                break;
              default:
                break;
            }
          }
        }
      }
  }
}
</script>
<style lang="scss" scoped>
h2,
h1,
h3 {
	font-weight: normal;
}

v-card:hover{
  background-color: rgba(#FFF, 0.9) !important
}
.v-card {
  color: rgb(88,0,0) !important;
  transition: opacity .4s ease-in-out !important;
  .v-btn {
    font-family: "western-regular", Fallback, sans-serif;
    background-color: black !important;
    color: rgb(213, 168, 168);
    margin: 5px;
    cursor: pointer;
    font-size: large;
    font-weight: normal;
    padding: 5px 5px 5px 5px;
    border: 3px solid #65172c;
  }
}

.v-card:not(.on-hover) {
  opacity: 0.8;
}

#demo-text {
	font-size: 20px;
	font-weight: 700;
  color:white;
// 	background-clip: text;
// 	-webkit-background-clip: text;
// 	color: transparent;
// 	background-image: url('../assets/img/bg.jpg');
}
</style>../assets/js/near/utils.xts