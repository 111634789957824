import Vue from 'vue'
import Vuetify from 'vuetify'
// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import TwitterIcon from '../components/TwitterIcon.vue'
import DiscordIcon from '../components/DiscordIcon.vue'

// import '@fortawesome/fontawesome-free/css/all.css'


Vue.use(Vuetify)

// const opts = {}

export default new Vuetify({
    icons: {
        values: {
            twitter: {
                component: TwitterIcon
            },  
            discord: {
                component: DiscordIcon
            }
        }
    }
})