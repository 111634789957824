import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";

import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupDefaultWallets } from "@near-wallet-selector/default-wallets";
import { setupSender } from "@near-wallet-selector/sender";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";

import { providers, connect, keyStores, WalletConnection } from "near-api-js";

const nearConfig = {
	networkId: "mainnet",
	nodeUrl: "https://rpc.mainnet.near.org",
	walletUrl: "https://wallet.near.org",
	helperUrl: "https://helper.mainnet.near.org",
	explorerUrl: "https://explorer.mainnet.near.org",
} 


const MARKETPLACE_CONTRACT = "staking.elcafecartel.near"

export async function initContract() {

  const _selector = await setupWalletSelector({
    network: "mainnet",
    modules: [
      // setupSender(),
      setupMeteorWallet(),
      setupNearWallet(),
      setupMyNearWallet(),
      setupHereWallet(),
      ...(await setupDefaultWallets()),
    ],
  });

  const _modal = setupModal(_selector, {
    contractId: MARKETPLACE_CONTRACT,
  });

  window.modal = _modal
  window.selector = _selector

  const _state = _selector.store.getState();
  window.state = _state;
  window.accounts = _state.accounts;

  console.log("State", _state);

  const near = await connect(
		Object.assign({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } }, nearConfig)
	);

  window.provider = new WalletConnection(near).account().connection.provider;

};

export function parseToken(amount) {
	return amount / 10 ** 12;
};

export function login(){
  window.modal.show();  
};

export async function logout(){
  // if (!window.selector) return;
		const _wallet = await window.selector.wallet();

		_wallet.signOut().catch((err) => {
			console.log("Failed to sign out");
			console.error(err);
		});

    location.reload();
};

export function check_userSession(){
  const accountId = window.accounts.find((account) => account.active)?.accountId || null;
  console.log(accountId)
};

export async function viewMethod(contractId, methodName, args) {
  if (! window.selector) return;

  const { network } =  window.selector.options;
  const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
  const res = await provider.query({
    request_type: "call_function",
    account_id: contractId,
    method_name: methodName,
    args_base64: Buffer.from(JSON.stringify(args)).toString("base64"),
    finality: "optimistic",
  });

  return JSON.parse(Buffer.from(res.result).toString());
}


export async function callMethod(params){
  const wallet = await window.selector.wallet();
  const transactions = [];


  for (let param of params) {
    transactions.push({
      signerId: param.accountId,
      receiverId: param.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: param.methodName,
            args: param.args || {},
            gas: param.gas ? param.gas : "250000000000000",
            deposit: param.amount ? param.amount.toString() : "1",
          },
        },
      ],
    });
  }

  const res = await wallet
    .signAndSendTransactions({
      transactions,
    })
    .catch((err) => {
      throw err;
    });
  return res;
};