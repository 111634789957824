import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';

// import "@mdi/font/css/materialdesignicons.css";


import { initContract } from "./assets/js/near/utils.tsx";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import "@near-wallet-selector/modal-ui/styles.css";

// import 'vue-toast-notification/dist/theme-default.css';


Vue.use(VueToast);
Vue.config.productionTip = false

window.nearInitPromise = initContract()
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }).catch(console.error);

