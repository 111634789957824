<template>
    <v-container>
        <img aspect-ratio="1" class="logo-img" src="../assets/img/Logos/main-logo.png" alt=""/>
    </v-container>
</template>
<script>
export default {
    name: "HomePage",
    methods: {}
}
</script>
<style lang="scss" scoped>
.logo-img {
    padding-right: 10%;
    padding-top: 1%;
    padding-left: 10%;
    display: block;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    // width: 75%;
    transform: translate(-50%, -50%);
}

// @media screen and (max-width: 700px) {
//   .logo-img {
//     // margin: 20% auto auto auto ;
//     padding-right: 10%;
//     padding-left: 10%;
//     display: block;
//     width: 100%;
//     }
// }

@media screen and (min-width: 700px) and (orientation:landscape)   {
  .logo-img {
    // margin: 20% auto auto auto ;
    padding-right: 10%;
    padding-top: 1%;
    padding-left: 10%;
    display: block;
    width: 65%;
    }
}

// @media screen and (max-width: 500px)  {
//   .logo-img {
//     // margin: 20% auto auto auto ;
//     padding-right: 10%;
//     padding-top: 1%;
//     padding-left: 10%;
//     display: block;
//     width: 85%;
//     }
// }


.container-scoped{ position: absolute; bottom: 0px }
</style>