<template>
    <v-container class="mt-5">
        <h2 style="text-align: center; margin-top: 25px; margin-bottom: 50px;">El Cafe Cartel Collections:</h2>

        <v-row>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                <v-card class="mx-auto pa-4" max-width="350px" style="background-color: rgba(88, 88, 88, 0.4); border-radius: 16px;"  >
                    <v-card-title pripary-title class="justify-center" style="color:white; margin-bottom: 10px">Cartel Legends</v-card-title>
                    <v-card-item>
                        <v-img  :src="gen0" cover class="rounded-pill" />
                    </v-card-item>
                    <v-card-actions class="justify-center mb-2 mt-6">
                        <v-btn
                            class="mx-4"
                            large
                            dark
                            href="https://paras.id/collection/cartel.neartopia.near" target="_blank"
                            elevation="6" 
                            color="#757575"
                            >Purchase
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                <v-card class="mx-auto pa-4" max-width="350px" style="background: rgba(88, 88, 88, 0.4);  border-radius: 16px;">
                    <v-card-title pripary-title class="justify-center" style="color:white; margin-bottom: 10px">El Cafe Cartel Gen 1</v-card-title>
                    <v-card-item>
                        <v-img  :src="gen1" cover class="rounded-pill" />
                    </v-card-item>
                    <v-card-actions class="justify-center mb-2 mt-6">
                        <v-btn
                            class="mx-4"
                            dark
                            large
                            href="https://paras.id/collection/cartelgen1.neartopia.near" target="_blank"
                            elevation="6" 
                            color="#808080"
                            >Purchase
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                <v-card class="mx-auto pa-4" max-width="350px" style="background: rgba(88, 88, 88, 0.4);  border-radius: 16px;">
                    <v-card-title pripary-title class="justify-center" style="color:white; margin-bottom: 10px">Cafe Citizens</v-card-title>
                    <v-card-item>
                        <v-img  :src="gen2" cover class="rounded-pill" />
                    </v-card-item>
                    <v-card-actions class="justify-center mb-2 mt-6">
                        <v-btn
                            class="mx-4"
                            dark
                            large
                            href="https://paras.id/collection/citizen.bodega-lab.near" target="_blank"
                            elevation="6" 
                            color="#808080"
                            >Purchase
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <h2 style="text-align: center; margin-top: 50px; margin-bottom: 50px;">Project's Mission:</h2>
        <v-row align="center" justify="center">
            <v-col cols="" sm="8" style="font-size: 20px;" class="align-center scope">
                <p>
                    Our signature NFT project, El Café Cartel initially targeted crypto natives through our gen 0 and gen 1 collections. 
                    Our users can stake these collections for our native token $CAFE which will power our swappable traits marketplace, 
                    bodega, and will be redeemable for our own merch and coffee.
                    <br/>
                </p>
                <p>
                    We will provide liquidity for $CAFE once we deem the market suitable.
                    A generous presale allocation is available for investors.
                    $CAFE will power attribute trading on bodega and is currently only available by staking our gen 0 and gen 1 NFT collections.
                    $CAFE can be redeemed for physical perks and rewards through Meta Café.
                </p>
            </v-col>
            <v-col sm="4" mt-5 class="d-flex align-center">
                    <v-img class="img-center" :src="cartel_token" max-width="500px"/>
            </v-col>
        </v-row>
        <h2 style="text-align: center; margin-top: 50px; margin-bottom: 25px;">Our Products</h2>
        <v-row align="center" justify="center">
            <v-col cols=""  sm="4" mt-5 class="d-flex align-center">
                <v-img class="img-center" :src="bodega_img" max-height="300px"/>
            </v-col>
            <v-col sm="8" style="font-size: 20px;" class="align-center" >
                <p>
                    Bodega is an innovative new NFT launchpad with built in traits swap tool and trait marketplace. 
                    It will launch our gen 2 collection at the beginning of Q1 2023 and will be made available to new and existing projects shortly after.
                    <br/>
                </p>
                <p>
                    Bodega provides a sustainable utility ecosystem for artists wishing to launch an NFT project on Near protocol.
                    Having the ability to add seasonal traits and new art drops to existing nft collections not only keeps communities engaged but opens 
                    collaboration potential with other artists and web3 projects.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=""  sm="4" mt-5 class="d-flex align-center">
                <v-img class="img-center" :src="meta_cafe" max-height="300px"/>
            </v-col>
            <v-col sm="8" style="font-size: 20px;" class=" d-flex align-center" >
                <p>
                    A Coffee Business built on Near Protocol. Meta Café will initially be a route to market for our own El Café Cartel branded coffee. 
                    Meta Café will be an ecommerce platform running solely on web3 harnessing the power and scalability of near blockchain.
                    The infrastructure we are building is designed to offer independent coffee roasters access to a web3 market and many other perks & utilities.
                </p>
            </v-col>
        </v-row>
        <h2 style="text-align: center; margin-top: 50px; margin-bottom: 25px;">Our Partners</h2>
        <v-row no-gutters>
            <template v-for="n in 5">
                <v-col :key="n" sm="4" cols="" md="4">
                    <v-card
                        style="background:transparent;border:none;font-size:20px;"
                        class="mx-auto pa-6"
                        outlined
                        tile
                    >
                    <v-card-title pripary-title class="justify-center" style="color:white">{{partners[n-1][0]}}</v-card-title>
                    <v-card-subtitle style="text-align:center;color:white"> {{partners[n-1][1]}}</v-card-subtitle>
                    </v-card>
                </v-col>
                <v-responsive
                    v-if="n === 3"
                    :key="`width-${n}`"
                    width="100%"
                ></v-responsive>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import cartel_token from "../assets/img/cafe_token.png"
import bodega_img from "../assets/img/project_description/bodega.png"
import meta_cafe from "../assets/img/project_description/meta_cafe.png"
import gen0 from "../assets/img/slider_img/cartelgen1_1.png"
import gen1 from "../assets/img/slider_img/cartelgen1_2.png"
import gen2 from "../assets/img/gen2.png"
export default {
data: () => ({
    gen0: gen0, 
    gen1: gen1, 
    gen2: gen2,
    cartel_token:cartel_token,
    bodega_img: bodega_img,
    meta_cafe: meta_cafe,
    partners:[
        ["CONSTANT COFFEE", "COFFEE ROASTERS"],
        ["PLAY EMBER", "WEB2 LAUNCH PARTNER"],
        ["KILLER MERTCH", "MERCHANDISE"],
        ["BEEXPERIENCE","ONBOARDING EDUCATION"],
        ["JUMP DEX", "DEFI SOLUTIONS"],
    ],
})
}
</script>
<style>
</style>